import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/": [~6],
		"/api/auth": [22],
		"/changelog": [23],
		"/(email-tests)/emails/quote-request-created": [~20,[5]],
		"/(email-tests)/emails/share": [~21,[5]],
		"/(app)/help": [12,[2],[3]],
		"/(app)/locations/[country=country]": [~14,[2],[3]],
		"/(app)/locations/[slug]": [~15,[2],[3]],
		"/(app)/locations/[...rest]": [~13,[2],[3]],
		"/(app)/(authorized)/plan": [7,[2],[3]],
		"/(app)/pricing": [~16,[2],[3]],
		"/(app)/(authorized)/profile": [8,[2],[3]],
		"/(app)/(authorized)/profile/subscriptions": [9,[2],[3]],
		"/(app)/(authorized)/proposals": [~10,[2],[3]],
		"/(app)/(authorized)/proposals/[proposalId]": [~11,[2],[3]],
		"/(auth)/reset-password": [18,[4]],
		"/(auth)/signin": [19,[4]],
		"/(app)/templates/create": [17,[2],[3]],
		"/widgets": [~24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';